<template>
  <Popper placement="left" arrow class="action-popper">
    <button
      v-if="
        !(
          (params.node.data.status == 'Тест завершен' ||
            params.node.data.reportLink) &&
          params.node.data.report === 'Для исследования'
        )
      "
      class="action-btn"
    >
      <i class="fal fa-list-ul"></i>
    </button>
    <template #content>
      <ul v-if="params.node.data.status !== 'Тест завершен'">
        <div v-if="params.node.data.status == 'Тест не выдан'">
          <giving-tests-mini
            :testOptionsCustom="params.node.data.testOptions"
            :groupId="params.group"
            :isSingle="true"
            :checkedList="[params.node.data.id]"
            :gridApi="params.api"
          />
        </div>
        <li
          v-if="
            params.node.data.status == 'Тест не пройден' ||
            params.node.data.status == 'В процессе прохождения'
          "
        >
          <button @click="remindTest">Напомнить пройти тест</button>
        </li>
        <li
          v-if="
            params.node.data.status == 'Тест не пройден' ||
            params.node.data.status == 'В процессе прохождения'
          "
        >
          <button @click="takePassing">Забрать тест</button>
        </li>
        <li
          v-if="
            params.node.data.status == 'Тест не пройден' ||
            params.node.data.status == 'В процессе прохождения'
          "
        >
          <button
            @click="copyToClipboard(`${url}/passing/${params.node.data.code}`)"
          >
            Копировать ссылку на тестирование
          </button>
        </li>
        <div v-if="isPreloader" class="btn-preloader">
          <mini-preloader />
        </div>
      </ul>
      <ul
        v-if="
          params.node.data.status == 'Тест завершен' ||
          params.node.data.reportLink
        "
      >
        <li @click="openReport">
          <button>Открыть отчет</button>
        </li>
        <li v-if="!params.node.data.allowViewReport" @click="sendReport">
          <button>Отправить отчет</button>
        </li>
        <div v-if="isPreloader" class="btn-preloader">
          <mini-preloader />
        </div>
      </ul>
    </template>
  </Popper>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import GivingTestsMini from "../../../feature/group-control/popups/GivingTestsMini.vue";
import { ref } from "@vue/reactivity";
import MiniPreloader from "../../Technical/MiniPreloader.vue";

export default {
  components: {
    GivingTestsMini,
    MiniPreloader,
  },
  setup(props) {
    const store = useStore();
    const url = process.env.VUE_APP_FRONT,
      reportLink = ref(null),
      isPreloader = ref(false);

    const remindTest = () => {
      store.dispatch("test/remindTest", props.params.node.data.code);
    };

    const takePassing = () => {
      //Костыль для бага снятия бага исчезновения галочек после действий без очищения списка выделения
      props.params.api.deselectAll();
      isPreloader.value = true;
      store
        .dispatch("group/takeStaffPassings", {
          id: props.params.group,
          staffIds: [props.params.node.data.id],
        })
        .finally(() => {
          isPreloader.value = false;
        });
    };

    const getReport = async () => {
      props.params.api.deselectAll();
      isPreloader.value = true;
      return store
        .dispatch("test/getReportLink", props.params.node.data.code)
        .then(({ data }) => {
          reportLink.value = data;
        })
        .finally(() => (isPreloader.value = false));
    };

    const openReport = async () => {
      props.params.api.deselectAll();
      await getReport();
      window.open(reportLink.value, "_blank");
    };

    const sendReport = () => {
      props.params.api.deselectAll();
      if (!props.params.node.data.allowViewReport) {
        store.dispatch("group/allowToViewReport", {
          id: props.params.group,
          staffIds: [props.params.node.data.id],
        });
      }
    };

    const copyReport = async () => {
      await getReport();
      copyToClipboard(reportLink.value);
    };

    const copyToClipboard = (link) => {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          notify({
            title: "Ссылка скопирована",
            type: "success",
          });
        })
        .catch((error) => {
          notify({
            title: "Произошла ошибка",
            text: error,
            type: "error",
          });
        });
    };

    return {
      remindTest,
      takePassing,
      openReport,
      copyReport,
      copyToClipboard,
      url,
      sendReport,
      isPreloader,
    };
  },
};
</script>
<style lang="scss">
.action-popper li {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.action-btn {
  height: 50px;
  width: 50px;
  padding-bottom: 5px;
}
ul {
  position: relative;

  button {
    width: 200px;
    height: 100%;
    text-align: start;
    padding: 10px;
  }
}
</style>