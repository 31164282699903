<template>
  <div v-if="checkAccess()">
    <button @click="enterCabinet" class="btn btn-accent btn-sm">
      Зайти в аккаунт
    </button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default {
  props: {
    id: {
      type: Number,
      required: false,
    },
    user_role: {
      type: String,
      required: false,
    }
  },
  setup(props) {
    let id = props.id || props.params.node.data.id;
    let user_role = props.user_role || props.params.node.data.role;
    const store = useStore();
    const role = computed(() =>
        store.state.profile.profile
          ? store.state.profile.profile.role
          : "private_person"
      ),
      token = computed(() => store.state.users.userToken);
    const enterCabinet = () => {
      store.commit("setIsGlobalLoad", true);
      store
        .dispatch("users/getUserToken", id)
        .then(() => {
          if (token.value) {
            if (!localStorage.getItem("prevToken"))
              localStorage.setItem(
                "prevToken",
                document.cookie.match(new RegExp("ei-token=([^;]*)"))[1]
              );
            window.location.href = `${process.env.VUE_APP_BACK}/auth/${token.value}`;
          }
        })
        .finally(() => store.commit("setIsGlobalLoad", false));
    };

    const checkAccess = () => {
      switch (role.value) {
        case "admin":
          return true;
        case "director":
          return (
            user_role == "Куратор" ||
            user_role == "Сотрудник"
          )
        case "curator":
          return user_role === "Сотрудник";
      }
    };

    return {
      enterCabinet,
      checkAccess,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;
  &:hover {
    color: var(--hover-color);
  }
}
</style>